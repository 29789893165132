

































































import { Component, Vue, Watch } from 'vue-property-decorator';
import OrderService from '@/common/services/order/order-service';
import { Order } from '@/common/models/order';
import displayMessage from '@/common/functions/display-message';
import { PaginationParams } from '@/common/models/pagination';

@Component
export default class OrderAdmin extends Vue {
  orderService!: OrderService;

  isLoading = true;

  isLoadingCsvButton = false;

  search = '';

  orders: Order[] = [];

  count = 0;

  itemsPerPage = 10;

  currentPage = 1;

  headers = [
    {
      text: 'ID',
      value: 'id',
      sortable: true,
    },
    {
      text: 'Nächstes Lieferdatum',
      value: 'next_delivery_at',
      sortable: true,
    },
    {
      text: 'Max. Menge',
      value: 'max_quantity',
      sortable: true,
    },
    {
      text: 'Menge',
      value: 'quantity',
      sortable: true,
    },
    {
      text: 'Adresse',
      value: 'user.profile.address',
      sortable: false,
    },
    {
      text: 'Einrichtung',
      value: 'user.profile.institution',
      sortable: true,
    },
    {
      text: 'Username',
      value: 'user.username',
      sortable: true,
    },
    {
      text: 'Aktion',
      value: 'actions',
      sortable: false,
    },
  ];

  get pageCount() {
    const division = this.count / this.itemsPerPage;
    return Math.ceil(division);
  }

  mounted() {
    this.orderService = new OrderService();
    this.getOrders();
  }

  async getOrders(params?: PaginationParams) {
    this.isLoading = true;

    const { data, statusCode, message } = await this.orderService.getOrders(params);
    if (statusCode === 200 && data) {
      this.orders = data.results;
      this.count = data.count;
    } else {
      displayMessage(message, true, this.$store);
    }

    this.isLoading = false;
  }

  async exportCSV() {
    this.isLoadingCsvButton = true;

    const response = await this.orderService.getOrdersCSV(this.search.trim());
    if (response.statusCode === 200) {
      displayMessage(response.message, false, this.$store);
    } else {
      displayMessage(response.message, true, this.$store);
    }

    this.isLoadingCsvButton = false;
  }

  cleanField(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.getOrderBySearch();
    }
    if (event.key === ' ') {
      const { value } = event.target as HTMLInputElement;
      if (!value.trim() || value.endsWith(' ')) {
        event.preventDefault();
      }
    }
  }

  async getOrderBySearch() {
    const params = this.getParams();
    this.getOrders(params);
  }

  getParams(): PaginationParams {
    const params: PaginationParams = {};
    if (this.search !== '') {
      params.search = this.search;
    }
    return params;
  }

  onPageChange(page: number) {
    const params = this.getParams();
    params.page = page;
    this.getOrders(params);
  }

  goToRoute(id: number, route: string) {
    if (this.$route.name !== route) {
      this.$router.push({ name: route, query: { userId: `${id}` } });
    }
  }

  @Watch('search')
  textFieldWithoutSpaces() {
    this.search = this.search.replace('  ', ' ').trim();
  }
}
